import CodeExample from "components/docs/code-examples/CodeExample";
import { Link } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import DocumentationSection from "../../components/docs/DocumentationSection";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { HTTPMethodBadge } from "../../components/docs/shared-components/MergeBadges";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { HTTPMethod, RouterLocation } from "../../types/types";

const HTTPMethodBadgeWithStyle = styled(HTTPMethodBadge)`
  font-size: 16px;
  line-height: 26px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
`;

interface Props {
  location: RouterLocation;
}
function IntegrationMetadataPage({ location }: Props) {
  const title = "Integration Metadata";
  const description =
    "Use Merge's Integration Metadata endpoint to programmatically fetch information about the integrations Merge offers.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <>
          <p>
            Use the Integration Metadata endpoint to fetch integration names, identifiers, images,
            brand colors, and more.
          </p>
          <p>
            You can use this data to create an integrations menu or applications marketplace for
            your product.
          </p>
          <div style={{ display: "flex", justifyContent: "center", margin: "64px 0" }}>
            <StaticImage
              src="../../assets/images/illustrations/app-marketplace.png"
              alt="Build your own app marketplace"
              quality={100}
              placeholder="none"
              layout="constrained"
              width={400}
            />
          </div>
        </>
      </DocumentationSection>
      <hr className="mt-6 mb-9" />
      <section id="request">
        <Row>
          <Col md={12} lg={6}>
            <DocumentationSection title="Request all Merge integrations">
              <div style={{ display: "flex", alignItems: "center" }} className="pb-9">
                <HTTPMethodBadgeWithStyle method={HTTPMethod.GET} />
                <Title className="my-0" style={{ marginLeft: "0" }}>
                  /api/integrations
                </Title>
              </div>
            </DocumentationSection>
          </Col>
          <Col md={12} lg={6}>
            <DocumentationSection title="Request my organization's enabled integrations">
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <HTTPMethodBadgeWithStyle method={HTTPMethod.GET} />
                  <Title className="my-0" style={{ marginLeft: "0" }}>
                    /api/organizations/integrations
                  </Title>
                </div>
                <p className="mt-3">
                  <b>Note:</b> This organization-specific endpoint differs in that:
                  <ul>
                    <li>
                      Only integrations enabled in{" "}
                      <a href="https://app.merge.dev/integrations/hris">Configuration</a> are
                      returned
                    </li>
                    <li>
                      It requires <Link to="/basics/authentication">API key authentication</Link>{" "}
                      (never store this in your frontend)
                    </li>
                    <li>
                      The response is <Link to="/basics/pagination">paginated</Link>
                    </li>
                  </ul>
                </p>
              </>
            </DocumentationSection>
          </Col>
        </Row>
      </section>

      <hr className="mt-6 mb-9" />
      <section id="response">
        <DocumentationSection title="Response">
          <CodeExample
            codeBlockName="Example Integration Metadata response"
            folder="basics/integration-metadata"
            colorScheme="light"
            hideCopyButtonWithHeader
            hasLineNumbers={false}
          />
        </DocumentationSection>
      </section>
      <CrossGuideLinks location={location} style={{ marginTop: "12px" }} />
    </StaticPageContainer>
  );
}

export default IntegrationMetadataPage;
